import React, { useCallback, useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { usePoll, useMediaModal, Content, LineReducer, LineSetContext, LineUI, Spinner, useNotification, SelectField, Label, Button, ButtonWithIcon, RadioButton, SmallInput, FilterDropdown, PageHeader} from 'scorer-ui-kit';
//import { EDGE_API_BASE_URL } from '../constants';
import styled, { css } from 'styled-components';
import { getEdgeApiBaseURL, getAllConfigData, getCamList, saveLocation, savePZ, savePS, saveRZ, saveArea, removeData } from 'services/apiConfig';
import { useTranslation } from 'react-i18next';
import BoxModal from 'components/BoxModal';
import { Buffer } from 'buffer';

interface IImageResponse {
  data: ArrayBuffer,
  status: number,
}

const Header = styled.div`
  width: 100%;
  max-width: 940px !important;
  //height: 108px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  line-height: 1.2;
  > div:first-child > div > a {
    max-width: 750px;
    overflow-wrap: break-word;
    white-space: break-spaces;
    bottom: 34.5px;
    top: unset;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const PageHeaderComponent = styled.div`
  display: flex;
  flex-direction: column;
`;

const FixedContent = styled(Content)`
  padding: 0px 90px;
`;


const MainContent = styled.div`
  margin: 0;
  max-width: 1200px !important;
  border-radius: 3px;
  background-color: #fafcfc;
  box-shadow: 0 4px 9px 0 rgba(152, 174, 189, 0.05);
  border: solid 1px #eee;
`;

const SpinnerContainer = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  height: 100%;
  border-radius: 3px;
  ${({ backgroundColor }) => css`
    background-color: ${backgroundColor ? backgroundColor : 'rgba(0, 0, 0, 0.35)'}
  `};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 4;
`;


const LineUIImageWrapper = styled.div<{ isReadOnly: boolean }>`
  width: 100%;
  height: 450px;
  transition:transform 40ms;
  transform-origin:center top;
  position: relative;
  & > div {
    height: 100%;
    display: flex;
    justify-content: center;
    > img {
      width: max-content;
      box-shadow: -2px 0 0 0 #ddd, 2px 0 0 0 #ddd;
    }
    & > div > svg > g:nth-child(n) {
      > text {
        display:none;
      }
      // > g:nth-child(n) {
      //   > text {
      //     display: none;
      //   }
      // }
    }
    
  }
  ${({ isReadOnly }) => isReadOnly && css`
    ::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 450px;
      z-index: 1;
    }
  `}
`;

const LineUIImageWrapper_Modal = styled.div<{ isReadOnly: boolean }>`
  width: 100%;
  transition:transform 40ms;
  transform-origin:center top;
  position: relative;
  & > div {
    height: 100%;
    display: flex;
    justify-content: center;
    > img {
      width: max-content;
      box-shadow: -2px 0 0 0 #ddd, 2px 0 0 0 #ddd;
    }
    & > div > svg > g:nth-child(n) {
      > text {
        display:none;
      }
      // > g:nth-child(n) {
      //   > text {
      //     display: none;
      //   }
      // }
    }
    
  }
  ${({ isReadOnly }) => isReadOnly && css`
    ::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 450px;
      z-index: 1;
    }
  `}
`;

const LineUIContainer = styled.div`
  width: 100%;
  max-width: 1200px !important;
  display: flex;
  margin: 30px 0 15px;
  position: relative;
`;

const LineUILeftPanel = styled.div`
  padding: 15px 0;
  float: left;
  width: 60%;
  #lineui-id { 
    pointer-events: none;
  }
`;

const LineUIRightPanel = styled.div`
  width: 100%;
  float: right;
  width: 40%;
`;

const StyledSelectField = styled(SelectField)`
  width: 209px;
  background: #fff;
  &:disabled {
    opacity: 0.7;
  }
  & + div {
    top: 50% !important;
    transform: translateY(-50%) !important;
  }
`;


const SidePaddingDiv = styled.div`
  position: relative;
  padding: 0 20px;
`;


const AreaButton = styled(Label)`
  & > span {
    float: left;
  }
  & > div {
    float: right;
  }
  font-size: 12px;
  border: 1.5px solid #eee;
  width: 20%;
  padding: 10px 15px 5px 15px;
`;



const CollapsibleDiv = styled.div`
  .accordion-without-js {
    padding: 30px 15px;
    max-width: 1000px;
    margin: 0 10px;
  }
  .accordion-without-js details {
    color: #222222;
    font-size: 1rem;
    line-height: 1.7;
  }
  .accordion-without-js details summary {
    margin-bottom: 20px;
    font-size: 1rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .accordion-without-js details summary:after  {
    display: inline-block;
    content: '';
    height: 1px;
    opacity: 0.1;
    background: #000000;
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: -2px;
  }
  
  .accordion-without-js details[open] summary {
    color: #000000;
    -webkit-transition: color .7s ease-in;
    transition: color .7s ease-in;
  }
`;

const InputAndButtonContainer = styled.div<{ gap: string }>`
  font-size: 14px;
  flex-flow: row wrap;
  display: flex;
  padding: 0 20px;
  align-items: center;
  gap: ${({ gap }) => gap};
  & > button {
    :disabled {
      background: #e9f0f6;
    }
  }
`;

const ButtonWithoutBorder = styled(ButtonWithIcon)`
  border: none;
  background: none;
  margin: 0 0 10px 20px;
`;

const FieldSetContainer = styled.div`
  fieldset {
    border: 1.5px solid #eee;
  }
  margin: 0 0 20px 20px;
`;

const BlockButton = styled(Label)`
  & > span {
    float: left;
  }
  & > div:nth-child(2) {
    float:right;
  }
  & > div:nth-child(3) {
    clear:both;
    display: flex;
    flex-direction: column;
    & > button {
      margin-bottom: 5px;
      border: none;
      color: white;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 4px 2px;
      cursor: pointer;
    }
    
  }
  font-size: 12px;
  border: 1.5px solid #eee;
  width: 30%;
  padding: 10px 15px 15px 15px;
`;

const ConfirmBox = styled.div`
  width: 800px;
  background-color: #fafcfc;
  padding: 20px;
`;

const ConfirmBoxWithLineUI = styled.div`
  width: 800px;
  background-color: #fafcfc;
  padding: 20px;
`;

const ConfirmButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  & > button:first-child{
    margin-right: 10px;
    background: #e4edf4;
  }
`;

const ButtonFormat = styled(Button)`
  &:focus {
    outline: 2px solid #838383;
  }
`;

const ButtonWithIconFormat = styled(ButtonWithIcon)`
  &:focus {
    outline: 2px solid #838383;
  }
`;

const ConfirmText = styled(Label)`
  font-size: 20px;
`;


// generate uuid
function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, 
        v = c === 'x' ? r : ((r && 0x3) || 0x8);
      return v.toString(16);
    });
}

//get index of elements that contain string
function getAllIndexes(arr:any, val:any) {
  const indexes = [];
  for(let i = 0; i < arr.length; i++)
    if (arr[i].area_id.includes(val))
      indexes.push(i);
  return indexes;
}

const CameraConfiguration: React.FC = React.memo(() => {
  const { isMediaUrlValid } = useMediaModal();
  const { sendNotification } = useNotification();
  const { t } = useTranslation(['CommonDict']);
  const [state, dispatch] = useReducer(LineReducer, []);
  const [lineUI_modal, dispatch_modal] = useReducer(LineReducer, []);

  const [camList, setCamList] = useState([]);
  const [cameraName, setCameraName] = useState('');
  const [isGetData, setIsGetData] = useState<boolean>(false);
  const [isCamChange, setIsCamChange] = useState<boolean>(false);
  

  const [image, setImage] = useState('');
  const [imageLoading, setImageLoading] = useState<boolean>(true);
  const [imageDetails, setImageDetails] = useState({ x: 1920, y: 1080 });

  const [edgeApiBaseURL, setEdgeApiBaseURL] = useState('');
  const [hasEdgeApiBaseURL, setHasEdgeApiBaseURL] = useState<boolean>(false);


  const [modalRemove, setModalRemove] = useState<boolean>(false);
  const [removeTarget, setRemoveTarget] = useState('');


  const [allData_PZ, setAllData_PZ] = useState<any>([]);
  const [allData_PS, setAllData_PS] = useState<any>([]);
  const [allData_RZ, setAllData_RZ] = useState<any>([]);
  const [allData_location, setAllData_Location] = useState<any>([]);
  const [allData_Area, setAllData_Area] = useState<any>([]);
  
  const [modalLocation, setModalLocation] = useState<boolean>(false);
  const [selectedLocationID, setSelectedLocationID] = useState(0);
  const [selectedLocationData, setSelectedLocationData] = useState({
    id: 0,
    name: '',
    business_start: '',
    business_end: '',
    congestion_threshold1: 50,
    congestion_threshold2: 80,
  });
  
  const [modalPZ, setModalPZ] = useState<boolean>(false);
  const [pzData, setPzData] = useState<any>([]);
  const [selectedPzAreaID, setSelectedPzAreaID] = useState<any>('');
  const [selectedPzData, setSelectedPzData] = useState({
    id: 0,
    location_name: '',
    name: '',
    stream_name: '',
    max_slot: 10,
    points: [{'x':30,'y':30},{'x':300,'y':30},{'x':300,'y':300},{'x':30,'y':300}],
    area_id: 'pz' + uuidv4(),
  });
  
  const [modalPS, setModalPS] = useState<boolean>(false);
  const [psData, setPsData] = useState<any>([]);
  const [selectedPsAreaID, setSelectedPsAreaID] = useState<any>('');
  const [selectedPsData, setSelectedPsData] = useState({
    id: 0,
    location_name: '',
    name: '',
    stream_name: '',
    points: [{'x':30,'y':30},{'x':300,'y':30},{'x':300,'y':300},{'x':30,'y':300}],
    area_id: 'ps' + uuidv4(),
  });

  const [modalRZ, setModalRZ] = useState<boolean>(false);
  const [rzData, setRzData] = useState<any>([]);
  const [selectedRzAreaID, setSelectedRzAreaID] = useState<any>('');
  const [selectedRzData, setSelectedRzData] = useState({
    id: 0,
    location_name: '',
    name: '',
    stream_name: '',
    max_slot: 10,
    points: [{'x':30,'y':30},{'x':300,'y':30},{'x':300,'y':300},{'x':30,'y':300}],
    area_id: 'rz' + uuidv4(),
  });

  const [modalAREA, setModalAREA] = useState<boolean>(false);
  const [selectedAreaID, setSelectedAreaID] = useState<any>('');
  const [selectedAreaData, setSelectedAreaData] = useState({
    id: 0,
    location_name: '',
    name: '',
    group_area_pz: [],
    group_area_ps: [],
    group_area_rz: [],
  });

  
  // *************************************
  // ++++++++++++++ utils ++++++++++++++++ 
  // *************************************
  // hide line ui 
  const showHideLineUI = useCallback((option: string, kind: string) => {
    const lineUI = document.getElementById('lineui-id');
    if (lineUI) {
      const svg: any = lineUI.querySelector('svg');
      if (svg) {
        for (let i = 0; i < svg.children.length; i++) {
          if (option === 'hide') {
            const new_state = JSON.parse(JSON.stringify(state));
            
            if(kind==='PZ'){
              const pz_indexList = getAllIndexes(new_state, 'pz');
              pz_indexList.forEach(index => {
                svg.children[index].style.display = 'none';
              });
            } else if(kind==='PS'){
              const ps_indexList = getAllIndexes(new_state, 'ps');
              ps_indexList.forEach(index => {
                svg.children[index].style.display = 'none';
              });
            } else if(kind==='RZ'){
              const rz_indexList = getAllIndexes(new_state, 'rz');
              rz_indexList.forEach(index => {
                svg.children[index].style.display = 'none';
              });
            }
          } else if(option==='show') {
            svg.children[i].style.display = '';
          }
        }
      }
    }
  }, [state]);
  
  //add point to edit modal
  const addPoint = useCallback(() => {
    dispatch_modal({
      type: 'ADD_POINT',
      index: 0,
    });
  }, []);

  // remove point from edit modal
  const removePoint = useCallback(() => {
    dispatch_modal({
      type: 'REMOVE_POINT',
      index: 0,
    });
  }, []);

  // get EDGE_API_BASE_URL
  const get_EDGE_API_BASE_URL = useCallback(async () => {
    try {
      const res: any = await getEdgeApiBaseURL();
      if (res.status === 200) {
        console.log(res.data);
        setEdgeApiBaseURL(res.data.edge_api_url);
        setHasEdgeApiBaseURL(true);
      }
    }
    catch (error) {
      console.error(error);
    }
  }, []);
  useEffect(() => {
    get_EDGE_API_BASE_URL();
  }, [get_EDGE_API_BASE_URL]);

  // Poll camera image
  const getCameraImage = useCallback(async () => {
    if (!hasEdgeApiBaseURL) return;
    if (cameraName) {
      try {
        const res: IImageResponse = await axios.get(`${edgeApiBaseURL}/api/v1/stacks/${cameraName}/snapshot?timestamp=${Date.now()}`, { responseType: 'arraybuffer' });
        if (res.status === 200 && res.data) {
          const imgBase64 = 'data:image/jpg;base64,' + Buffer.from(res.data).toString('base64');
          const isImageValid = await isMediaUrlValid(imgBase64, 'img');
          if (isImageValid === true) {
            const img = new Image();
            img.src = imgBase64;
            setImage(imgBase64);
            setImageDetails({
              x: img.naturalWidth,
              y: img.naturalHeight
            });
          }
        }
      } catch (err) {
        console.error(err);
      }
      setImageLoading(false);
    }
  }, [hasEdgeApiBaseURL, edgeApiBaseURL, cameraName, isMediaUrlValid]);
  useEffect(() => {
    if (isCamChange){
      getCameraImage();
      setIsCamChange(false);
    }
  }, [isCamChange, getCameraImage]);
  usePoll(async () => {
    getCameraImage();
  }, 10000);  // 10s
  // *************************************

  
  // *************************************
  // ++++++++++++++ init ++++++++++++++++ 
  // *************************************
  // get camera list
  const getCameraData = useCallback(async() => {
    try{
      const res: any = await getCamList();
      if (res.status === 200 && res.data) {
        const data: any = res.data.data;
        setCamList(data);
        setCameraName(data[0]);
      }
    }catch(error){
      console.error(error);
    }
  }, []);
  useEffect(() => {
    getCameraData();
  }, [getCameraData]);

  // get all config data
  const getAllData = useCallback(async () => {
    try {
      const res: any = await getAllConfigData();
      if (res.status === 200) {
        const data = res.data.data;
        setAllData_Location(data.location);
        setAllData_PZ(data.parking_zone);
        setAllData_PS(data.parking_slot);
        setAllData_RZ(data.roadway_zone);
        setAllData_Area(data.area);

        data.location.length !== 0 && setSelectedLocationID(data.location[0].id);
        data.area.length !== 0 && setSelectedAreaID(data.area[0].id);
        setIsGetData(true);
      }
    }
    catch (error) {
      console.error(error);
    }
  }, []);
  useEffect(() => {
    getAllData();
  }, [getAllData]);

  // set line ui
  useEffect(() => {
    const filterByCameraName = (dataSet: any[]) => dataSet.filter(item => item.stream_name === cameraName);
    const result = {
      parking_zone: filterByCameraName(allData_PZ),
      parking_slot: filterByCameraName(allData_PS),
      roadway_zone: filterByCameraName(allData_RZ),
    };
    const line_ui_state = [...result.parking_zone, ...result.parking_slot, ...result.roadway_zone];
    dispatch({ type: 'LOAD', state: line_ui_state });
  }, [cameraName, allData_PZ, allData_PS, allData_RZ]);

  // set line ui color
  useEffect(() => {
    if(!imageLoading){
      setTimeout(() => {
        const new_state = JSON.parse(JSON.stringify(state));
        const pz_indexList = getAllIndexes(new_state, 'pz');
        const ps_indexList = getAllIndexes(new_state, 'ps');
        const rz_indexList = getAllIndexes(new_state, 'rz');
        
        const lineUI = document.getElementById('lineui-id') as HTMLElement;
        const svg: any = lineUI.querySelector('svg');
        if (svg) {
          for (let i = 0; i < svg.children.length; i++) {
  
            if (pz_indexList.includes(i)){
              const g = svg.children[i];
              for (let x = 0; x < g.children.length; x++) {
                const line = g.children[x].getElementsByTagName('line');
                if(line.length > 0){
                  line[1].style.stroke = '#D1679F';
                }
                
              }
            } 
            else if(ps_indexList.includes(i)){
              const g = svg.children[i];
              for (let x = 0; x < g.children.length; x++) {
                const line = g.children[x].getElementsByTagName('line');
                if(line.length > 0){
                  line[1].style.stroke = '#BDD167';
                }
                
              }
            } else if(rz_indexList.includes(i)){
              const g = svg.children[i];
              for (let x = 0; x < g.children.length; x++) {
                const line = g.children[x].getElementsByTagName('line');
                if(line.length > 0){
                  line[1].style.stroke = '#67ACD1';
                }
                
              }
            } 
          }
        }
      }, 100);    
    }
  }, [imageLoading, state]);
  // *************************************


  // *************************************
  // ++++++++++++++ main ++++++++++++++++ 
  // *************************************
  // ----------- change camera -----------------
  useEffect(() => {
    if (!isGetData || !cameraName) return;
    const pzData = allData_PZ.filter((item: any) => item.stream_name === cameraName);
    const psData = allData_PS.filter((item: any) => item.stream_name === cameraName);
    const rzData = allData_RZ.filter((item: any) => item.stream_name === cameraName);
    setSelectedPzAreaID(pzData.length === 0 ? '' : pzData[0].area_id);
    setSelectedPsAreaID(psData.length === 0 ? '' : psData[0].area_id);
    setSelectedRzAreaID(rzData.length === 0 ? '' : rzData[0].area_id);
    setPzData(pzData);
    setPsData(psData);
    setRzData(rzData);
    setIsCamChange(true);
  }, [isGetData, cameraName, allData_PZ, allData_PS, allData_RZ]);


  // ----------- Remove -----------------
  const handle_remove_Modal = useCallback((target) => {
    setRemoveTarget(target);
    setModalRemove(true);
  }, []);

  const handle_remove_data = useCallback(async () => {
    try {
      const targetID =
        removeTarget === 'LOCATION' ? selectedLocationID : 
          removeTarget === 'AREA' ? selectedAreaID : 
            removeTarget === 'PZ' ? selectedPzAreaID : 
              removeTarget === 'PS' ? selectedPsAreaID : 
                removeTarget === 'RZ' && selectedRzAreaID;

      const res = await removeData({removeTarget, targetID});
      if (res.status === 200 && res.data.status_code === 200) {
        if (removeTarget === 'LOCATION'){
          setAllData_Location((prevState: any) => {
            const dataExists = prevState.some((item: any) => item.id === targetID);
            if (dataExists) return prevState.filter((item: any) => item.id !== targetID);
            else return prevState;
          });
        }
        else if (removeTarget === 'AREA'){
          setAllData_Area((prevState: any) => {
            const dataExists = prevState.some((item: any) => item.id === targetID);
            if (dataExists) return prevState.filter((item: any) => item.id !== targetID);
            else return prevState;
          });
        }
        else if (removeTarget === 'PZ'){
          setPzData((prevState: any) => {
            const dataExists = prevState.some((item: any) => item.area_id === targetID);
            if (dataExists) return prevState.filter((item: any) => item.area_id !== targetID);
            else return prevState;
          });
          setAllData_PZ((prevState: any) => {
            const dataExists = prevState.some((item: any) => item.area_id === targetID);
            if (dataExists) return prevState.filter((item: any) => item.area_id !== targetID);
            else return prevState;
          });
        }
        else if (removeTarget === 'PS'){
          setPsData((prevState: any) => {
            const dataExists = prevState.some((item: any) => item.area_id === targetID);
            if (dataExists) return prevState.filter((item: any) => item.area_id !== targetID);
            else return prevState;
          });
          setAllData_PS((prevState: any) => {
            const dataExists = prevState.some((item: any) => item.area_id === targetID);
            if (dataExists) return prevState.filter((item: any) => item.area_id !== targetID);
            else return prevState;
          });
        }
        else if (removeTarget === 'RZ'){
          setRzData((prevState: any) => {
            const dataExists = prevState.some((item: any) => item.area_id === targetID);
            if (dataExists) return prevState.filter((item: any) => item.area_id !== targetID);
            else return prevState;
          });
          setAllData_RZ((prevState: any) => {
            const dataExists = prevState.some((item: any) => item.area_id === targetID);
            if (dataExists) return prevState.filter((item: any) => item.area_id !== targetID);
            else return prevState;
          });
        }

        sendNotification({ type: 'success', message: t('Removed successfully.') });
      }else{
        sendNotification({ type: 'error', message: t('Failed to remove.') });
      }
    } catch (error) {
      console.error(error);
      sendNotification({ type: 'error', message: t('Failed to remove.') });
    }
    setModalRemove(false);
  }, [sendNotification, t, removeTarget, selectedLocationID, selectedPzAreaID, selectedPsAreaID, selectedRzAreaID, selectedAreaID]);



  // ----------- Location -----------------
  const button_add_Location = useCallback(() => {
    setSelectedLocationData({
      id: 0,
      name: '',
      business_start: '00:00:00',
      business_end: '23:59:59',
      congestion_threshold1: 50,
      congestion_threshold2: 80,
    });
    setModalLocation(true);
  }, []);

  const button_edit_Location = useCallback(() => {
    setSelectedLocationData(allData_location.find((item: any) => item.id === selectedLocationID));
    setModalLocation(true);
  }, [selectedLocationID, allData_location]);

  const save_Location = useCallback(async () => {
    try {
      const isNameDuplicate = allData_location.some((item: any) => 
        item.name === selectedLocationData.name && item.id !== selectedLocationData.id 
      );
      if(isNameDuplicate) {
        setModalLocation(false);
        sendNotification({ type: 'error', message: t('Name is duplicate, failed to save.') });
        return;
      }
      if(selectedLocationData.name === '') {
        setModalLocation(false);
        sendNotification({ type: 'error', message: t('Name is null, failed to save.') });
        return;
      }

      const res = await saveLocation({'locationData': selectedLocationData});
      if (res.status === 200 && res.data.status_code === 200) {
        const new_data = JSON.parse(JSON.stringify(selectedLocationData));
        if (res.data.data.id !== 0) new_data.id = res.data.data.id;  // res.data.data.id:0はupdate、0以外は新規追加
        setAllData_Location((prevState: any) => {
          const dataExists = prevState.some((item: any) => item.id === new_data.id);
          if (dataExists) {
            return prevState.map((item: any) => item.id === new_data.id ? new_data : item);
          } else {
            return [...prevState, new_data];
          }
        });
        sendNotification({ type: 'success', message: t('Saved area successfully.') });
      }else{
        sendNotification({ type: 'error', message: t('Failed to save.') });
      }
    } catch (error) {
      console.error(error);
      sendNotification({ type: 'error', message: t('Failed to save.') });
    }
    setModalLocation(false);
  }, [allData_location, selectedLocationData, sendNotification, t]);
  // ------------------------------------------



  // ----------- PZ -----------------
  const button_add_PZ = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const area_id = 'pz' + uuidv4();
    const data = {
      id: 0,
      location_name: allData_location[0].name,
      name: '',
      stream_name: cameraName,
      max_slot: 10,
      points: [{'x':30,'y':30},{'x':300,'y':30},{'x':300,'y':300},{'x':30,'y':300}],
      area_id: area_id,
    };
    dispatch_modal({type: 'LOAD', state: [data]});
    setSelectedPzAreaID(area_id);
    setSelectedPzData(data);
    setModalPZ(true);
  }, [allData_location, cameraName]);

  const button_edit_PZ = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const data = pzData.find((item: any) => item.area_id === selectedPzAreaID);
    dispatch_modal({type: 'LOAD', state: [data]});
    setSelectedPzData(data);
    setModalPZ(true);
  }, [pzData, selectedPzAreaID]);

  const save_PZ = useCallback(async () => {
    try {
      const isNameDuplicate = allData_PZ.some((item: any) => 
        item.name === selectedPzData.name && item.id !== selectedPzData.id
      );
      if(isNameDuplicate) {
        setModalPZ(false);
        sendNotification({ type: 'error', message: t('Name is duplicate, failed to save.') });
        return;
      }
      if(selectedPzData.name === '') {
        setModalPZ(false);
        sendNotification({ type: 'error', message: t('Name is null, failed to save.') });
        return;
      }

      const res = await savePZ({'pzData': selectedPzData, 'area': lineUI_modal[0].points});
      if (res.status === 200 && res.data.status_code === 200) {
        const new_data = JSON.parse(JSON.stringify(selectedPzData));
        new_data.points = lineUI_modal[0].points;
        if (res.data.data.id !== 0) new_data.id = res.data.data.id;
        setPzData((prevState: any) => {
          const dataExists = prevState.some((item: any) => item.id === new_data.id);
          if (dataExists) {
            return prevState.map((item: any) => item.id === new_data.id ? new_data : item);
          } else {
            return [...prevState, new_data];
          }
        });
        setAllData_PZ((prevState: any) => {
          const dataExists = prevState.some((item: any) => item.id === new_data.id);
          if (dataExists) {
            return prevState.map((item: any) => item.id === new_data.id ? new_data : item);
          } else {
            return [...prevState, new_data];
          }
        });
        sendNotification({ type: 'success', message: t('Saved area successfully.') });
      }else{
        sendNotification({ type: 'error', message: t('Failed to save.') });
      }
    } catch (error) {
      console.error(error);
      sendNotification({ type: 'error', message: t('Failed to save.') });
    }
    setModalPZ(false);
  }, [allData_PZ,lineUI_modal, selectedPzData, sendNotification, t]);
  // ------------------------------------------


  
  // ----------- PS -----------------
  const button_add_PS = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const area_id = 'ps' + uuidv4();
    const data = {
      id: 0,
      location_name: allData_location[0].name,
      name: '',
      stream_name: cameraName,
      points: [{'x':30,'y':30},{'x':300,'y':30},{'x':300,'y':300},{'x':30,'y':300}],
      area_id: area_id,
    };
    dispatch_modal({type: 'LOAD', state: [data]});
    setSelectedPsAreaID(area_id);
    setSelectedPsData(data);
    setModalPS(true);
  }, [allData_location, cameraName]);

  const button_edit_PS = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const data = psData.find((item: any) => item.area_id === selectedPsAreaID);
    dispatch_modal({type: 'LOAD', state: [data]});
    setSelectedPsData(data);
    setModalPS(true);
  }, [psData, selectedPsAreaID]);

  const save_PS = useCallback(async () => {
    try {
      const isNameDuplicate = allData_PS.some((item: any) =>
        item.name === selectedPsData.name && item.id !== selectedPsData.id
      );
      if(isNameDuplicate) {
        setModalPS(false);
        sendNotification({ type: 'error', message: t('Name is duplicate, failed to save.') });
        return;
      }
      if(selectedPsData.name === '') {
        setModalPS(false);
        sendNotification({ type: 'error', message: t('Name is null, failed to save.') });
        return;
      }

      const res = await savePS({'psData': selectedPsData, 'area': lineUI_modal[0].points});
      if (res.status === 200 && res.data.status_code === 200) {
        const new_data = JSON.parse(JSON.stringify(selectedPsData));
        new_data.points = lineUI_modal[0].points;
        if (res.data.data.id !== 0) new_data.id = res.data.data.id;
        setPsData((prevState: any) => {
          const dataExists = prevState.some((item: any) => item.id === new_data.id);
          if (dataExists) {
            return prevState.map((item: any) => item.id === new_data.id ? new_data : item);
          } else {
            return [...prevState, new_data];
          }
        });
        setAllData_PS((prevState: any) => {
          const dataExists = prevState.some((item: any) => item.id === new_data.id);
          if (dataExists) {
            return prevState.map((item: any) => item.id === new_data.id ? new_data : item);
          } else {
            return [...prevState, new_data];
          }
        });
        sendNotification({ type: 'success', message: t('Saved area successfully.') });
      }else{
        sendNotification({ type: 'error', message: t('Failed to save.') });
      }
    } catch (error) {
      console.error(error);
      sendNotification({ type: 'error', message: t('Failed to save.') });
    }
    setModalPS(false);
  }, [allData_PS, selectedPsData, lineUI_modal, sendNotification, t]);
  // ------------------------------------------

  // ----------- RZ -----------------
  const button_add_RZ = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const area_id = 'rz' + uuidv4();
    const data = {
      id: 0,
      location_name: allData_location[0].name,
      name: '',
      stream_name: cameraName,
      max_slot: 10,
      points: [{'x':30,'y':30},{'x':300,'y':30},{'x':300,'y':300},{'x':30,'y':300}],
      area_id: area_id,
    };
    dispatch_modal({type: 'LOAD', state: [data]});
    setSelectedRzAreaID(area_id);
    setSelectedRzData(data);
    setModalRZ(true);
  }, [allData_location, cameraName]);

  const button_edit_RZ = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const data = rzData.find((item: any) => item.area_id === selectedRzAreaID);
    dispatch_modal({type: 'LOAD', state: [data]});
    setSelectedRzData(data);
    setModalRZ(true);
  }, [rzData, selectedRzAreaID]);

  const save_RZ = useCallback(async () => {
    try {
      const isNameDuplicate = allData_RZ.some((item: any) =>
        item.name === selectedRzData.name && item.id !== selectedRzData.id
      );
      if(isNameDuplicate) {
        setModalRZ(false);
        sendNotification({ type: 'error', message: t('Name is duplicate, failed to save.') });
        return;
      }
      if(selectedRzData.name === '') {
        setModalRZ(false);
        sendNotification({ type: 'error', message: t('Name is null, failed to save.') });
        return;
      }

      const res = await saveRZ({'rzData': selectedRzData, 'area': lineUI_modal[0].points});
      if (res.status === 200 && res.data.status_code === 200) {
        const new_data = JSON.parse(JSON.stringify(selectedRzData));
        new_data.points = lineUI_modal[0].points;
        if (res.data.data.id !== 0) new_data.id = res.data.data.id;
        setRzData((prevState: any) => {
          const dataExists = prevState.some((item: any) => item.id === new_data.id);
          if (dataExists) {
            return prevState.map((item: any) => item.id === new_data.id ? new_data : item);
          } else {
            return [...prevState, new_data];
          }
        });
        setAllData_RZ((prevState: any) => {
          const dataExists = prevState.some((item: any) => item.id === new_data.id);
          if (dataExists) {
            return prevState.map((item: any) => item.id === new_data.id ? new_data : item);
          } else {
            return [...prevState, new_data];
          }
        });
        sendNotification({ type: 'success', message: t('Saved area successfully.') });
      }else{
        sendNotification({ type: 'error', message: t('Failed to save.') });
      }
    } catch (error) {
      console.error(error);
      sendNotification({ type: 'error', message: t('Failed to save.') });
    }
    setModalRZ(false);
  }, [allData_RZ, lineUI_modal, selectedRzData, sendNotification, t]);
  // ------------------------------------------


  // ----------- Area -----------------
  const handle_add_Area = useCallback(() => {
    setSelectedAreaData({
      id: 0,
      location_name: allData_location[0].name,
      name: '',
      group_area_pz: [],
      group_area_ps: [],
      group_area_rz: [],
    });
    setModalAREA(true);
  }, [allData_location]);

  const handle_edit_Area = useCallback(() => {
    setSelectedAreaData(allData_Area.find((item: any) => item.id === selectedAreaID));
    setModalAREA(true);
  }, [selectedAreaID, allData_Area]);
  
  const handle_save_Area = useCallback(async () => {
    try {
      const isNameDuplicate = allData_Area.some((item: any) =>
        item.name === selectedAreaData.name && item.id !== selectedAreaData.id 
      );
      if(isNameDuplicate) {
        setModalAREA(false);
        sendNotification({ type: 'error', message: t('Name is duplicate, failed to save.') });
        return;
      }
      if(selectedAreaData.name === '') {
        setModalAREA(false);
        sendNotification({ type: 'error', message: t('Name is null, failed to save.') });
        return;
      }

      const res = await saveArea({'areaData': selectedAreaData});
      if (res.status === 200 && res.data.status_code === 200) {
        const new_data = JSON.parse(JSON.stringify(selectedAreaData));
        if (res.data.data.id !== 0) new_data.id = res.data.data.id;  // res.data.data.id:0はupdate、0以外は新規追加
        setAllData_Area((prevState: any) => {
          const dataExists = prevState.some((item: any) => item.id === new_data.id);
          if (dataExists) {
            return prevState.map((item: any) => item.id === new_data.id ? new_data : item);
          } else {
            return [...prevState, new_data];
          }
        });
        sendNotification({ type: 'success', message: t('Saved area successfully.') });
      }else{
        sendNotification({ type: 'error', message: t('Failed to save.') });
      }
    } catch (error) {
      console.error(error);
      sendNotification({ type: 'error', message: t('Failed to save.') });
    }
    setModalAREA(false);
  }, [allData_Area, selectedAreaData, sendNotification, t]);
  // ------------------------------------------


  return (
    <FixedContent>
      <Header>
        <PageHeaderComponent>
          <PageHeader
            areaTitle={t('Configuration')}
            icon='Analyse'
            updateDocTitle={false}
            title={cameraName}
          />
        </PageHeaderComponent>
      </Header>
      <MainContent>
        <LineUIContainer>
          <LineUIImageWrapper isReadOnly={imageLoading} id='lineui-id'>
            <LineSetContext.Provider value={{ state, dispatch }}>
              {imageLoading ?
                <SpinnerContainer>
                  <Spinner size='large' styling='primary' />
                </SpinnerContainer>
                :
                <div>
                  <LineUI
                    options={{
                      showSetIndex: false,
                      showPoint: false,
                      showMoveHandle: false,
                      showPointHandle: false,
                      setIndexOffset: 0,
                      boundaryOffset: Math.round(imageDetails.x * 2.5 / 100),
                      fixedImgDimensions: imageDetails
                    }}
                    src={image}
                  />
                </div>}
            </LineSetContext.Provider>
          </LineUIImageWrapper>
        </LineUIContainer>

        <InputAndButtonContainer gap='0 20px'>
          <StyledSelectField
            isCompact
            disabled={imageLoading}
            label={{htmlFor: 'cam_select',text: t('Select Camera')}}
            placeholder={cameraName? cameraName : t('Choose Camera...')}
            changeCallback={(e)=>{setImageLoading(true); setCameraName(e);}}
          >
            {camList.map((item: any, index: any) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </StyledSelectField>
          <ButtonWithIcon
            icon='PasswordHide'
            position='left'
            size='small'
            design='secondary'
            onClick={()=>{showHideLineUI('hide','PZ');}}
            disabled={imageLoading}
          >
            {t('NoPzLineUI')}
          </ButtonWithIcon>
          <ButtonWithIcon
            icon='PasswordHide'
            position='left'
            size='small'
            design='secondary'
            onClick={()=>{showHideLineUI('hide','PS');}}
            disabled={imageLoading}
          >
            {t('NoPsLineUI')}
          </ButtonWithIcon>
          <ButtonWithIcon
            icon='PasswordHide'
            position='left'
            size='small'
            design='secondary'
            onClick={()=>{showHideLineUI('hide','RZ');}}
            disabled={imageLoading}
          >
            {t('NoRzLineUI')}
          </ButtonWithIcon>
          <ButtonWithIcon
            icon='PasswordShow'
            position='left'
            size='small'
            design='secondary'
            onClick={()=>{showHideLineUI('show', '');}}
            disabled={imageLoading}
          >
            {t('showAllLineUI')}
          </ButtonWithIcon>
        </InputAndButtonContainer>

        <CollapsibleDiv>
          <section className='accordion-without-js'>
            {/*<details>
              <summary>{t('LocationConfig')}</summary>
              <FieldSetContainer>
                <fieldset>
                  <legend>
                    <ButtonWithoutBorder
                      icon='Add'
                      position='left'
                      size='small'
                      design='secondary'
                      onClick={button_add_Location}
                      disabled={imageLoading}
                    >
                      {t('Add')}
                    </ButtonWithoutBorder>
                    <ButtonWithoutBorder
                      icon='Edit'
                      position='left'
                      size='small'
                      design='secondary'
                      onClick={button_edit_Location}
                      disabled={imageLoading || allData_location.length === 0}
                    >
                      {t('Edit')}
                    </ButtonWithoutBorder>
                    <ButtonWithoutBorder
                      icon='ActionRemove'
                      position='left'
                      size='small'
                      design='secondary'
                      onClick={()=> handle_remove_Modal('LOCATION')}
                      disabled={imageLoading || allData_location.length === 0}
                    >
                      {t('Remove')}
                    </ButtonWithoutBorder>
                  </legend>
                  <InputAndButtonContainer gap='0 50px'>
                    {allData_location.length === 0 ? <b>{t('noLocationConfig')}</b> : 
                      allData_location.map((item: any, index: any) => {
                        return (
                          <AreaButton
                            htmlFor={item.id}
                            labelText={item.name}
                            key={index}
                          >
                            <RadioButton
                              disabled={imageLoading}
                              currentChecked={selectedLocationID === 0 ? allData_location[0].id : selectedLocationID}
                              id={item.id}
                              name={item.name}
                              onChangeCallback={(e: any)=>setSelectedLocationID(Number(e))}
                              value={item.id}
                            />
                          </AreaButton>
                        );
                      })}
                  </InputAndButtonContainer>
                </fieldset>
              </FieldSetContainer>
                    </details>*/}
            <details>
              <summary>{t('PzConfig')}</summary>
              <FieldSetContainer>
                <fieldset>
                  <legend>
                    <ButtonWithoutBorder
                      icon='Add'
                      position='left'
                      size='small'
                      design='secondary'
                      onClick={button_add_PZ}
                      disabled={imageLoading}
                    >
                      {t('Add')}
                    </ButtonWithoutBorder>
                    <ButtonWithoutBorder
                      icon='Edit'
                      position='left'
                      size='small'
                      design='secondary'
                      onClick={button_edit_PZ}
                      disabled={imageLoading || pzData.length === 0}
                    >
                      {t('Edit')}
                    </ButtonWithoutBorder>
                    <ButtonWithoutBorder
                      icon='ActionRemove'
                      position='left'
                      size='small'
                      design='secondary'
                      onClick={()=> handle_remove_Modal('PZ')}
                      disabled={imageLoading || pzData.length === 0}
                    >
                      {t('Remove')}
                    </ButtonWithoutBorder>
                  </legend>
                  <InputAndButtonContainer gap='0 20px'>
                    {pzData.length === 0 ? <b>{t('noPzConfig')}</b> :
                      pzData.map((item: any, index: any) => {
                        return (
                          <BlockButton htmlFor={item.area_id} labelText={item.name} key={index}>
                            <RadioButton
                              disabled={imageLoading}
                              currentChecked={selectedPzAreaID}
                              id={item.area_id}
                              name={item.area_id}
                              onChangeCallback={(e)=>setSelectedPzAreaID(e)}
                              value={item.area_id}
                            />
                            <div><fieldset><legend>{t('maxSlot')}</legend>{item.max_slot}</fieldset></div>
                          </BlockButton>
                        );
                      })}
                  </InputAndButtonContainer>
                </fieldset>
              </FieldSetContainer>
            </details>
            <details>
              <summary>{t('PsConfig')}</summary>
              <FieldSetContainer>
                <fieldset>
                  <legend>
                    <ButtonWithoutBorder
                      icon='Add'
                      position='left'
                      size='small'
                      design='secondary'
                      onClick={button_add_PS}
                      disabled={imageLoading}
                    >
                      {t('Add')}
                    </ButtonWithoutBorder>
                    <ButtonWithoutBorder
                      icon='Edit'
                      position='left'
                      size='small'
                      design='secondary'
                      onClick={button_edit_PS}
                      disabled={imageLoading || psData.length === 0}
                    >
                      {t('Edit')}
                    </ButtonWithoutBorder>
                    <ButtonWithoutBorder
                      icon='ActionRemove'
                      position='left'
                      size='small'
                      design='secondary'
                      onClick={()=>handle_remove_Modal('PS')}
                      disabled={imageLoading || psData.length === 0}
                    >
                      {t('Remove')}
                    </ButtonWithoutBorder>
                  </legend>
                  <InputAndButtonContainer gap='0 20px'>
                    {psData.length === 0 ? <b>{t('noPsConfig')}</b> :
                      psData.map((item: any, index: any) => {
                        return (
                          <AreaButton htmlFor={item.area_id} labelText={item.name} key={index}>
                            <RadioButton
                              disabled={imageLoading}
                              currentChecked={selectedPsAreaID}
                              id={item.area_id}
                              name={item.area_id}
                              onChangeCallback={(e)=>setSelectedPsAreaID(e)}
                              value={item.area_id}
                            />
                          </AreaButton>
                        );
                      })}
                  </InputAndButtonContainer>
                </fieldset>
              </FieldSetContainer>
            </details>
            <details>
              <summary>{t('RzConfig')}</summary>
              <FieldSetContainer>
                <fieldset>
                  <legend>
                    <ButtonWithoutBorder
                      icon='Add'
                      position='left'
                      size='small'
                      design='secondary'
                      onClick={button_add_RZ}
                      disabled={imageLoading}
                    >
                      {t('Add')}
                    </ButtonWithoutBorder>
                    <ButtonWithoutBorder
                      icon='Edit'
                      position='left'
                      size='small'
                      design='secondary'
                      onClick={button_edit_RZ}
                      disabled={imageLoading || rzData.length === 0}
                    >
                      {t('Edit')}
                    </ButtonWithoutBorder>
                    <ButtonWithoutBorder
                      icon='ActionRemove'
                      position='left'
                      size='small'
                      design='secondary'
                      onClick={()=> handle_remove_Modal('RZ')}
                      disabled={imageLoading || rzData.length === 0}
                    >
                      {t('Remove')}
                    </ButtonWithoutBorder>
                  </legend>
                  <InputAndButtonContainer gap='0 20px'>
                    {rzData.length === 0 ? <b>{t('noRzConfig')}</b> :
                      rzData.map((item: any, index: any) => {
                        return (
                          <BlockButton htmlFor={item.area_id} labelText={item.name} key={index}>
                            <RadioButton
                              disabled={imageLoading}
                              currentChecked={selectedRzAreaID}
                              id={item.area_id}
                              name={item.area_id}
                              onChangeCallback={(e)=>setSelectedRzAreaID(e)}
                              value={item.area_id}
                            />
                            <div><fieldset><legend>{t('maxSlot')}</legend>{item.max_slot}</fieldset></div>
                          </BlockButton>
                        );
                      })}
                  </InputAndButtonContainer>
                </fieldset>
              </FieldSetContainer>
            </details>
            <details>
              <summary>{t('AreaConfig')}</summary>
              <FieldSetContainer>
                <fieldset>
                  <legend> 
                    <ButtonWithoutBorder
                      icon='Add'
                      position='left'
                      size='small'
                      design='secondary'
                      onClick={handle_add_Area}
                      disabled={imageLoading}
                    >
                      {t('Add')}
                    </ButtonWithoutBorder>
                    <ButtonWithoutBorder
                      icon='Edit'
                      position='left'
                      size='small'
                      design='secondary'
                      onClick={handle_edit_Area}
                      disabled={imageLoading || allData_Area.length === 0}
                    >
                      {t('Edit')}
                    </ButtonWithoutBorder>
                    <ButtonWithoutBorder
                      icon='ActionRemove'
                      position='left'
                      size='small'
                      design='secondary'
                      onClick={()=> handle_remove_Modal('AREA')}
                      disabled={imageLoading || allData_Area.length === 0}
                    >
                      {t('Remove')}
                    </ButtonWithoutBorder>
                  </legend>
                  <InputAndButtonContainer gap='0 50px'>
                    {allData_Area.length === 0 ? <b>{t('noAreaConfig')}</b> :
                      allData_Area.map((item: any, index: any) => {
                        return (
                          <AreaButton
                            htmlFor={'area'+item.id}
                            labelText={item.name}
                            key={index}
                          >
                            <RadioButton
                              disabled={imageLoading}
                              currentChecked={'area'+selectedAreaID}
                              id={'area'+item.id}
                              name={'area'+item.id}
                              onChangeCallback={(e)=>setSelectedAreaID(Number(String(e).split('area').pop()))}
                              value={'area'+item.id}
                            />
                          </AreaButton>
                        );
                      })}
                  </InputAndButtonContainer>
                </fieldset>
              </FieldSetContainer>
            </details>
          </section>
        </CollapsibleDiv>




        {/*----------------------------------*/}
        {/*-----------  Remove  -------------*/}
        {/*----------------------------------*/}
        <BoxModal
          isCloseEnable={false}
          isOpen={modalRemove}
          onDismiss={()=> setModalRemove(false)}
          customeComponent={
            <ConfirmBox>
              <ConfirmText htmlFor='' labelText={t('Remove')} />
              <ConfirmText htmlFor='' labelText={t('Are you sure you want to delete the selected content?')} />
              <ConfirmButtonBox>
                <ButtonFormat autoFocus id='CancelButton' design='secondary' onClick={() => setModalRemove(false)}>{t('Close')}</ButtonFormat>
                <ButtonWithIconFormat tabIndex={0} design='primary' icon='Delete' position='left' onClick={handle_remove_data}>{t('Delete')}</ButtonWithIconFormat>
              </ConfirmButtonBox>
            </ConfirmBox>
          } 
        />


        {/*----------------------------------*/}
        {/*----------- location -------------*/}
        {/*----------------------------------*/}
        <BoxModal
          isCloseEnable={false}
          isOpen={modalLocation}
          onDismiss={()=> {}}
          customeComponent={
            <ConfirmBox>
              <ConfirmText htmlFor='' labelText={t('Edit Location')} />
              <SmallInput
                label={t('location name')}
                type='text'
                defaultValue={selectedLocationData.name}
                onChange={(e) => setSelectedLocationData({...selectedLocationData, 'name': e.target.value})}
              />
              <SmallInput
                label={t('business start')}
                type='text'
                defaultValue={selectedLocationData.business_start}
                onChange={(e) => setSelectedLocationData({...selectedLocationData, 'business_start': e.target.value})}
              />
              <SmallInput
                label={t('business end')}
                type='text'
                defaultValue={selectedLocationData.business_end}
                onChange={(e) => setSelectedLocationData({...selectedLocationData, 'business_end': e.target.value})}
              />
              <SmallInput
                label={t('Congestion threshold 1')}
                type='text'
                defaultValue={selectedLocationData.congestion_threshold1}
                onChange={(e) => setSelectedLocationData({...selectedLocationData, 'congestion_threshold1': Number(e.target.value)})}
              />
              <SmallInput
                label={t('Congestion threshold 2')}
                type='text'
                defaultValue={selectedLocationData.congestion_threshold2}
                onChange={(e) => setSelectedLocationData({...selectedLocationData, 'congestion_threshold2': Number(e.target.value)})}
              />
              <ConfirmButtonBox>
                <ButtonFormat autoFocus id='CancelButton' design='secondary' onClick={() => setModalLocation(false)}>{t('Close')}</ButtonFormat>
                <ButtonWithIconFormat tabIndex={0} design='primary' icon='Add' position='left' onClick={save_Location}>{t('Save')}</ButtonWithIconFormat>
              </ConfirmButtonBox>
            </ConfirmBox>
          } 
        />

        {/************************************/}
        {/*************   pz   **************/}
        {/************************************/}
        <BoxModal
          isCloseEnable={false}
          isOpen={modalPZ}
          onDismiss={()=> {}}
          customeComponent={
            <ConfirmBoxWithLineUI>
              <ConfirmText htmlFor='' labelText={selectedPzData.id === 0 ? t('Add PZ') : t('Edit PZ')} />
              <LineUIImageWrapper_Modal isReadOnly={imageLoading}>
                <LineSetContext.Provider value={{ 'state': lineUI_modal, 'dispatch': dispatch_modal }}>
                  {imageLoading ?
                    <SpinnerContainer>
                      <Spinner size='large' styling='primary' />
                    </SpinnerContainer>
                    :
                    <div>
                      <LineUI
                        options={{
                          showSetIndex: false,
                          showPoint: true,
                          showMoveHandle: false,
                          showPointHandle: true,
                          setIndexOffset: 0,
                          boundaryOffset: Math.round(imageDetails.x * 2.5 / 100),
                          fixedImgDimensions: imageDetails
                        }}
                        src={image}
                      />
                    </div>}
                </LineSetContext.Provider>
              </LineUIImageWrapper_Modal>
              <ButtonWithoutBorder
                icon='Add'
                position='left'
                size='small'
                design='secondary'
                onClick={addPoint}
                disabled={imageLoading}
              >
                {t('addLineUi')}
              </ButtonWithoutBorder>
              <ButtonWithoutBorder
                icon='ActionRemove'
                position='left'
                size='small'
                design='secondary'
                onClick={removePoint}
                disabled={imageLoading}
              >
                {t('removeLineUi')}
              </ButtonWithoutBorder>
              <SmallInput
                label={t('pz name')}
                name='area_name'
                type='text'
                defaultValue={selectedPzData.name}
                onChange={(e) => setSelectedPzData({...selectedPzData, 'name': e.target.value})}
              />
              <StyledSelectField
                isCompact
                placeholder={t('Choose location...')}
                changeCallback={(e) => setSelectedPzData({...selectedPzData, 'location_name': e})}
                disabled={imageLoading}
                label={{htmlFor: 'loc_select',text: t('Choose location...')}}
                defaultValue={
                  selectedPzData.location_name === '' ? 1
                    : allData_location.find((item: any) =>item.name === selectedPzData.location_name).name
                }
              >
                {allData_location.map((item: any, index: any) => 
                  <option key={index} value={item.name}>{item.name}</option>
                )}
              </StyledSelectField>
              <SmallInput
                label={t('maxSlot')}
                name='max_slot'
                placeholder='please enter value...'
                type='text'
                unit={t('car unit')}
                value={selectedPzData.max_slot}
                onChange={(e) => {
                  const newValue = e.target.value.replace(/[^0-9]/g, '');
                  setSelectedPzData({...selectedPzData, 'max_slot': Number(newValue) >= 0 ? Number(newValue) : 0});
                }}
              />
              
              <ConfirmButtonBox>
                <ButtonFormat autoFocus id='CancelButton' design='secondary' onClick={() => {setSelectedPzAreaID(pzData[0]?.area_id); setModalPZ(false);}}>{t('Close')}</ButtonFormat>
                <ButtonWithIconFormat tabIndex={0} design='primary' icon='Add' position='left' onClick={save_PZ}>{t('Save')}</ButtonWithIconFormat>
              </ConfirmButtonBox>
            </ConfirmBoxWithLineUI>
          } 
        />

        {/************************************/}
        {/*************   PS   **************/}
        {/************************************/}
        <BoxModal
          isCloseEnable={false}
          isOpen={modalPS}
          onDismiss={()=> {}}
          customeComponent={
            <ConfirmBoxWithLineUI>
              <ConfirmText htmlFor='' labelText={selectedPsData.id === 0 ? t('Add PS') : t('Edit PS')} />
              <LineUIImageWrapper_Modal isReadOnly={imageLoading}>
                <LineSetContext.Provider value={{ 'state': lineUI_modal, 'dispatch': dispatch_modal }}>
                  {imageLoading ?
                    <SpinnerContainer>
                      <Spinner size='large' styling='primary' />
                    </SpinnerContainer>
                    :
                    <div>
                      <LineUI
                        options={{
                          showSetIndex: false,
                          showPoint: true,
                          showMoveHandle: false,
                          showPointHandle: true,
                          setIndexOffset: 0,
                          boundaryOffset: Math.round(imageDetails.x * 2.5 / 100),
                          fixedImgDimensions: imageDetails
                        }}
                        src={image}
                      />
                    </div>}
                </LineSetContext.Provider>
              </LineUIImageWrapper_Modal>
              <ButtonWithoutBorder
                icon='Add'
                position='left'
                size='small'
                design='secondary'
                onClick={addPoint}
                disabled={imageLoading}
              >
                {t('addLineUi')}
              </ButtonWithoutBorder>
              <ButtonWithoutBorder
                icon='ActionRemove'
                position='left'
                size='small'
                design='secondary'
                onClick={removePoint}
                disabled={imageLoading}
              >
                {t('removeLineUi')}
              </ButtonWithoutBorder>
              <SmallInput
                label={t('ps name')}
                name='area_name'
                type='text'
                defaultValue={selectedPsData.name}
                onChange={(e) => setSelectedPsData({...selectedPsData, 'name': e.target.value})}
              />
              <StyledSelectField
                isCompact
                placeholder={t('Choose location...')}
                changeCallback={(e) => setSelectedPsData({...selectedPsData, 'location_name': e})}
                disabled={imageLoading}
                label={{htmlFor: 'loc_select',text: t('Choose location...')}}
                defaultValue={
                  selectedPsData.location_name === '' ? 1
                    : allData_location.find((item: any) =>item.name === selectedPsData.location_name).name
                }
              >
                {allData_location.map((item: any, index: any) => 
                  <option key={index} value={item.name}>{item.name}</option>
                )}
              </StyledSelectField>              
              <ConfirmButtonBox>
                <ButtonFormat autoFocus id='CancelButton' design='secondary' onClick={() => {setSelectedPsAreaID(psData[0]?.area_id); setModalPS(false);}}>{t('Close')}</ButtonFormat>
                <ButtonWithIconFormat tabIndex={0} design='primary' icon='Add' position='left' onClick={save_PS}>{t('Save')}</ButtonWithIconFormat>
              </ConfirmButtonBox>
            </ConfirmBoxWithLineUI>
          } 
        />



        {/************************************/}
        {/*************   RZ   **************/}
        {/************************************/}
        <BoxModal
          isCloseEnable={false}
          isOpen={modalRZ}
          onDismiss={()=> {}}
          customeComponent={
            <ConfirmBoxWithLineUI>
              <ConfirmText htmlFor='' labelText={selectedRzData.id === 0 ? t('Add RZ') : t('Edit RZ')} />
              <LineUIImageWrapper_Modal isReadOnly={imageLoading}>
                <LineSetContext.Provider value={{ 'state': lineUI_modal, 'dispatch': dispatch_modal }}>
                  {imageLoading ?
                    <SpinnerContainer>
                      <Spinner size='large' styling='primary' />
                    </SpinnerContainer>
                    :
                    <div>
                      <LineUI
                        options={{
                          showSetIndex: false,
                          showPoint: true,
                          showMoveHandle: false,
                          showPointHandle: true,
                          setIndexOffset: 0,
                          boundaryOffset: Math.round(imageDetails.x * 2.5 / 100),
                          fixedImgDimensions: imageDetails
                        }}
                        src={image}
                      />
                    </div>}
                </LineSetContext.Provider>
              </LineUIImageWrapper_Modal>
              <ButtonWithoutBorder
                icon='Add'
                position='left'
                size='small'
                design='secondary'
                onClick={addPoint}
                disabled={imageLoading}
              >
                {t('addLineUi')}
              </ButtonWithoutBorder>
              <ButtonWithoutBorder
                icon='ActionRemove'
                position='left'
                size='small'
                design='secondary'
                onClick={removePoint}
                disabled={imageLoading}
              >
                {t('removeLineUi')}
              </ButtonWithoutBorder>
              <SmallInput
                label={t('rz name')}
                name='area_name'
                type='text'
                defaultValue={selectedRzData.name}
                onChange={(e) => setSelectedRzData({...selectedRzData, 'name': e.target.value})}
              />
              <StyledSelectField
                isCompact
                placeholder={t('Choose location...')}
                changeCallback={(e) => setSelectedRzData({...selectedRzData, 'location_name': e})}
                disabled={imageLoading}
                label={{htmlFor: 'loc_select',text: t('Choose location...')}}
                defaultValue={
                  selectedRzData.location_name === '' ? 1
                    : allData_location.find((item: any) =>item.name === selectedRzData.location_name).name
                }
              >
                {allData_location.map((item: any, index: any) => 
                  <option key={index} value={item.name}>{item.name}</option>
                )}
              </StyledSelectField>
              <SmallInput
                label={t('maxSlot')}
                name='max_slot'
                placeholder='please enter value...'
                type='text'
                unit={t('car unit')}
                value={selectedRzData.max_slot}
                onChange={(e) => {
                  const newValue = e.target.value.replace(/[^0-9]/g, '');
                  setSelectedRzData({...selectedRzData, 'max_slot': Number(newValue) >= 0 ? Number(newValue) : 0});
                }}
              />
              
              <ConfirmButtonBox>
                <ButtonFormat autoFocus id='CancelButton' design='secondary' onClick={() => {setSelectedRzAreaID(rzData[0]?.area_id); setModalRZ(false);}}>{t('Close')}</ButtonFormat>
                <ButtonWithIconFormat tabIndex={0} design='primary' icon='Add' position='left' onClick={save_RZ}>{t('Save')}</ButtonWithIconFormat>
              </ConfirmButtonBox>
            </ConfirmBoxWithLineUI>
          } 
        />

        
        {/************************************/}
        {/*************   AREA   *************/}
        {/************************************/}
        <BoxModal 
          isCloseEnable={false}
          isOpen={modalAREA}
          onDismiss={()=> {}}
          customeComponent={
            <ConfirmBox>
              <ConfirmText htmlFor='' labelText={selectedAreaData.id === 0 ? t('Add Area') : t('Edit Area')} />
              <SmallInput
                label={t('area name')}
                name='area_name'
                type='text'
                defaultValue={selectedAreaData.name}
                onChange={(e) => setSelectedAreaData({...selectedAreaData, 'name': e.target.value})}
              />
              <StyledSelectField
                isCompact
                placeholder={t('Choose location...')}
                changeCallback={(e) => setSelectedAreaData({...selectedAreaData, 'location_name': e})}
                disabled={imageLoading}
                label={{htmlFor: 'loc_select',text: t('Choose location...')}}
                defaultValue={
                  selectedAreaData.location_name === '' ? 1
                    : allData_location.find((item: any) =>item.name === selectedAreaData.location_name).name
                }
              >
                {allData_location.map((item: any, index: any) => 
                  <option key={index} value={item.name}>{item.name}</option>
                )}
              </StyledSelectField>
              <Label htmlFor='' labelText={t('pz_under_area')}>
                <FilterDropdown
                  buttonIcon='MetaCategories'
                  buttonText='Select PZ'
                  hasOptionsFilter
                  list={allData_PZ.length === 0 ? '' : allData_PZ.map((item: any) => ({text: item.name, value: item.id}))}
                  loadingText='ローディング...'
                  maxDisplayedItems={6}
                  optionType='checkbox'
                  searchPlaceholder='検索...'
                  searchResultText='[VISIBLE] 〜 [TOTAL]件'
                  selected={
                    selectedAreaData.group_area_pz.length === 0 ? []
                      : allData_PZ
                        .filter((item: any) => (selectedAreaData.group_area_pz as number[]).includes(item.id))
                        .map((item: any) => ({text: item.name, value: item.id}))
                  }
                  onSelect={
                    (e)=>setSelectedAreaData(
                      {...selectedAreaData,
                        'group_area_pz': JSON.parse(JSON.stringify(e)) === null ? []
                          : JSON.parse(JSON.stringify(e)).map((item: any) => (item.value))
                      })
                  }
                />
                <br /><br />
                {(allData_PZ === 0 || selectedAreaData.group_area_pz.length === 0) ? '' :
                  selectedAreaData.group_area_pz.map((item: any, index: any) => {
                    const data = allData_PZ.find((i: any) =>i.id === item);
                    return (
                      <ButtonWithIcon
                        icon='Analyse'
                        position='left'
                        size='xsmall'
                        design='secondary'
                        key={index}
                        value={data.id}
                      >
                        {data.name}
                      </ButtonWithIcon>
                    );
                  })}
              </Label>
              <Label htmlFor='' labelText={t('ps_under_area')}>
                <FilterDropdown
                  buttonIcon='MetaCategories'
                  buttonText='Select PS'
                  hasOptionsFilter
                  list={allData_PS === 0 ? '' : allData_PS.map((item: any) => ({text: item.name, value: item.id}))}
                  loadingText='ローディング...'
                  maxDisplayedItems={6}
                  optionType='checkbox'
                  searchPlaceholder='検索...'
                  searchResultText='[VISIBLE] 〜 [TOTAL]件'
                  selected={
                    selectedAreaData.group_area_ps.length === 0 ? []
                      : allData_PS
                        .filter((item: any) => (selectedAreaData.group_area_ps as number[]).includes(item.id))
                        .map((item: any) => ({text: item.name, value: item.id}))
                  }
                  onSelect={
                    (e)=>setSelectedAreaData(
                      {...selectedAreaData,
                        'group_area_ps': JSON.parse(JSON.stringify(e)) === null ? []
                          : JSON.parse(JSON.stringify(e)).map((item: any) => (item.value))
                      })
                  }
                />
                <br /><br />
                {(allData_PS === 0 || selectedAreaData.group_area_ps.length === 0) ? '' :
                  selectedAreaData.group_area_ps.map((item: any, index: any) => {
                    const data = allData_PS.find((i: any) =>i.id === item);
                    return (
                      <ButtonWithIcon
                        icon='Analyse'
                        position='left'
                        size='xsmall'
                        design='secondary'
                        key={index}
                        value={data.id}
                      >
                        {data.name}
                      </ButtonWithIcon>
                    );
                  })}
              </Label>
              <Label htmlFor='' labelText={t('rz_under_area')}>
                <FilterDropdown
                  buttonIcon='MetaCategories'
                  buttonText='Select RZ'
                  hasOptionsFilter
                  list={allData_RZ === 0 ? '' : allData_RZ.map((item: any) => ({text: item.name, value: item.id}))}
                  loadingText='ローディング...'
                  maxDisplayedItems={6}
                  optionType='checkbox'
                  searchPlaceholder='検索...'
                  searchResultText='[VISIBLE] 〜 [TOTAL]件'
                  selected={
                    selectedAreaData.group_area_rz.length === 0 ? []
                      : allData_RZ
                        .filter((item: any) => (selectedAreaData.group_area_rz as number[]).includes(item.id))
                        .map((item: any) => ({text: item.name, value: item.id}))
                  }
                  onSelect={
                    (e)=>setSelectedAreaData(
                      {...selectedAreaData,
                        'group_area_rz': JSON.parse(JSON.stringify(e)) === null ? []
                          : JSON.parse(JSON.stringify(e)).map((item: any) => (item.value))
                      })
                  }
                />
                <br /><br />
                {(allData_RZ === 0 || selectedAreaData.group_area_rz.length === 0) ? '' :
                  selectedAreaData.group_area_rz.map((item: any, index: any) => {
                    const data = allData_RZ.find((i: any) =>i.id === item);
                    return (
                      <ButtonWithIcon
                        icon='Analyse'
                        position='left'
                        size='xsmall'
                        design='secondary'
                        key={index}
                        value={data.id}
                      >
                        {data.name}
                      </ButtonWithIcon>
                    );
                  })}
              </Label>
              <ConfirmButtonBox>
                <ButtonFormat autoFocus id='CancelButton' design='secondary' onClick={() => setModalAREA(false)}>{t('Close')}</ButtonFormat>
                <ButtonWithIconFormat tabIndex={0} design='primary' icon='Add' position='left' onClick={handle_save_Area}>{t('Save')}</ButtonWithIconFormat>
              </ConfirmButtonBox>
            </ConfirmBox>
          }
        />
  
      </MainContent>
    </FixedContent>
  );
});

export default CameraConfiguration;