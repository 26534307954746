import React, {FC} from 'react';
import RoutePath from 'Route';
import {Layout} from 'scorer-ui-kit';
import Configuration from 'pages/Configuration';

const App: FC = () => {
  return (
    <Layout>
      <RoutePath path='/' exact component={Configuration} />
    </Layout>
  );
};

export default App;
