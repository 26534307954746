import axios from 'axios';
import { BASE_API_URL } from '../constants';

export const getEdgeApiBaseURL = async () => {
  return await axios.get<any>(`${BASE_API_URL}/api/getEdgeApiBaseURL`);
};

export const getCamList = async () => {
  return await axios.get<any>(`${BASE_API_URL}/api/getCamList`);
};

export const getAllConfigData = async () => {
  return await axios.get<any>(`${BASE_API_URL}/api/getAllConfigData`);
};

export const saveLocation = async (param:any) => {
  return await axios.post<any>(`${BASE_API_URL}/api/saveLocation`, param);
};

export const savePZ = async (param:any) => {
  return await axios.post<any>(`${BASE_API_URL}/api/savePZ`, param);
};

export const savePS = async (param:any) => {
  return await axios.post<any>(`${BASE_API_URL}/api/savePS`, param);
};

export const saveRZ = async (param:any) => {
  return await axios.post<any>(`${BASE_API_URL}/api/saveRZ`, param);
};

export const saveArea = async (param:any) => {
  return await axios.post<any>(`${BASE_API_URL}/api/saveArea`, param);
};


export const removeData = async (param:any) => {
  return await axios.post<any>(`${BASE_API_URL}/api/removeData`, param);
};